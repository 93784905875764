export default {
  computed: {
    student () {
      return this.$store.state.persons.persons.find(person => person.PersonID === this.$route.params.student_id)
    },

    childFriendlySkillCategories () {
      if (this.student && this.student.SkillCategoryHistory) {
        return Object.values(this.student.SkillCategoryHistory).filter((x) => { return x.DisplayType === 'CHILD_FRIENDLY' })
      }

      return []
    },

    skillCategory () {
      if (this.student) {
        const SkillCategory = this.childFriendlySkillCategories.find((x) => { return x.SkillCategoryID === this.$route.params.group_id })
        return SkillCategory || false
      }
    },

    skills () {
      return this.skillCategory?.Skills || false
    },

    skill () {
      return this.skillCategory?.Skills?.find(x => x.SkillID === this.$route.params.skill_id) || {}
    },

    skillPassed () {
      if (this.skill && this.skill.Rating && this.skill.MaxRating) {
        const rating = parseInt(this.skill.Rating)
        const maxRating = parseInt(this.skill.MaxRating)
        return rating !== 0 && maxRating !== 0 && rating === maxRating
      }

      return false
    },

    skillsPassed () {
      if (this.skills) {
        return this.skills.filter(skill => {
          const rating = parseInt(skill.Rating)
          const maxRating = parseInt(skill.MaxRating)
          return rating && maxRating && rating === maxRating
        }).length
      }

      return 0
    },

    allComplete () {
      if (this.skillCategory.Skills.length) {
        return this.skillCategory.Skills.every(skill => {
          const rating = parseInt(skill.Rating)
          const maxRating = parseInt(skill.MaxRating)
          return rating && maxRating && rating === maxRating
        })
      }

      return false
    }
  }
}
