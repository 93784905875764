<template>
  <component :is="link ? 'router-link' : 'div'" :to="link ? { name: 'stickerdetail', params: { student_id: student.PersonID, group_id: group.SkillCategoryID, skill_id: skill.SkillID } } : null" :class="[{ 'completed': completed, 'new': skill && (parseInt(skill.Rating) === skill.MaxRating && !celebratedSkill)  }]">
    <div class="sticker animate__animated animate__bounceIn" :class="{ ['animation-delay-'+(index + 1)]: true }">

      <div v-if="grain" class="sticker-grain animate__animated animate__bounceIn" :class="{ ['animation-delay-'+(index + 1)]: true, 'not-finished': !allComplete }"
        :style="{ backgroundImage: `url(${require(`@/img/grain${!allComplete ? '-not-finished' : ''}.png`)})` }"
      ></div>

      <div v-if="locked" class="locked">
        <font-awesome-icon :icon="['fas', 'lock']" />
      </div>

      <div class="sticker-complete animate__animated animate__bounceIn" :class="{ [`animation-delay-${index + 1}`]: true, 'clickable': celebrationPossible }" v-show="completed && showHorn && !allComplete" @click="$emit('partyHornClicked')">
        <font-awesome-icon :icon="['fas', 'party-horn']" />
      </div>

      <img :src="image" class="image" :class="{ completed }" v-if="image"/>
    </div>
    <div class="sticker-text" v-if="showName">
      {{ grain ? $t('context.complete') : skill.Name }}
    </div>
  </component>
</template>

<script>
export default {
  name: 'TheSticker',

  props: {
    student: Object,

    group: Object,

    skill: Object,

    image: {
      type: [String, Boolean],
      required: false,
      default: () => false
    },

    index: {
      type: Number,
      required: false,
      default: 0
    },

    grain: Boolean,

    // complete: Boolean,
    allComplete: Boolean,

    link: {
      type: Boolean,
      required: false,
      default: true
    },

    showName: {
      type: Boolean,
      required: false,
      default: true
    },

    showHorn: {
      type: Boolean,
      required: false,
      default: true
    },

    celebrationPossible: Boolean,

    locked: Boolean
  },

  computed: {
    completed () {
      return (this.skill && parseInt(this.skill.Rating) === this.skill.MaxRating) || this.allComplete
    },

    selectedPersonID () {
      return this.$store.state.persons.selectedPersonID
    },

    celebratedSkill () {
      return this.$store.getters['persons/celebrationWatched'](this.selectedPersonID + '-' + this.skill.SkillID)
    }
  }
}
</script>
