import { studentTracking } from '@/http'

export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_PERSONS = 'SET_PERSONS'
export const SET_LOADING = 'SET_LOADING'
export const SET_SEARCHRESULTS = 'SET_SEARCHRESULTS'
export const SET_SELECTED_PERSON = 'SET_SELECTED_PERSON'
export const SET_CELEBRATION_WATCHED = 'SET_CELEBRATION_WATCHED'

const sortPersons = (a, b) => {
  const startA = new Date(a.DateOfBirth)
  const startB = new Date(b.DateOfBirth)
  if (startA < startB) {
    return -1
  }
  if (startA > startB) {
    return 1
  }
  return 0
}

// What should I wipe on a logout?
const defaultState = () => ({
  persons: [],
  selectedPersonID: null
})

export default {
  namespaced: true,

  state: {
    loading: false,
    persons: [],
    searchedPersons: [],
    selectedPersonID: null,
    celebrationsWatched: []
  },

  actions: {
    fetch ({ commit, dispatch }) {
      commit(SET_LOADING, true)
      return studentTracking.get('persons').then(user => {
        commit(SET_PERSONS, user.data.items || [])
        commit(SET_LOADING, false)
      }).catch(() => {
        commit(SET_PERSONS, [])
        commit(SET_LOADING, false)
      })
    },

    searchStudents ({ commit }, payload) {
      commit(SET_SEARCHRESULTS, [])
      commit(SET_LOADING, true)
      return studentTracking.get('persons', {
        params: {
          search: payload.searchString
        }
      }).then(response => {
        commit(SET_LOADING, false)
        commit(SET_SEARCHRESULTS, response.data.items || [])
      })
        .catch(error => {
          commit(SET_LOADING, false)
          console.log(error)
        })
    },

    selectPerson ({ commit }, id) {
      commit(SET_SELECTED_PERSON, id)
    },

    setCelebrationWatched ({ commit }, payload) {
      commit('SET_CELEBRATION_WATCHED', payload)
    },

    clear ({ commit }) {
      commit(CLEAR_STATE)
    }
  },

  mutations: {
    [CLEAR_STATE] (state) {
      Object.keys(defaultState()).forEach(key => {
        state[key] = defaultState()[key]
      })
      state.viewDate = new Date()
    },

    [SET_PERSONS] (state, persons) {
      state.persons = persons
    },
    [SET_LOADING] (state, loading) {
      state.loading = loading
    },
    [SET_SEARCHRESULTS] (state, persons) {
      state.searchedPersons = persons
    },
    [SET_SELECTED_PERSON] (state, id) {
      state.selectedPersonID = id
    },
    [SET_CELEBRATION_WATCHED] (state, payload) {
      state.celebrationsWatched.push(payload)
    }
  },

  getters: {
    all: state => state.persons,
    own: state => state.persons.sort(sortPersons),
    searchResults: state => state.searchedPersons,
    celebrationWatched: state => {
      return (celebrationID) => state.celebrationsWatched.includes(celebrationID)
    }
  }
}
